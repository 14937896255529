import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { DeparturesList } from 'layouts';
import { GET_VALID_DEPARTURES } from 'actions/tours';
import { BASE_URL } from 'config';

class DeparturesPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      departures: [],
      loading: true,
    };
  }

  componentDidMount() {
    (async () => {
      // Get departures fresh!
      const DEPARTURES = await GET_VALID_DEPARTURES();
      if (DEPARTURES && DEPARTURES.success) {
        this.setState({
          departures: DEPARTURES.data,
          loading: false,
        });
      }
    })();
  }


  /**
   * Render
   */
  render() {
    const { departures, loading } = this.state;

    return (
      <Layout page="departures">
        <Helmet
          title="Haka Tours Departures"
          meta={[
            {
              name: 'description',
              content: 'An overview of all upcoming Haka Tours departures',
            },
            {
              name: 'og:title',
              content:
                'Haka Tours Departures',
            },
            {
              name: 'og:description',
              content: 'An overview of all upcoming Haka Tours departures',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/departures/` }]}
        />
        <DeparturesList list={departures} loading={loading} />
      </Layout>
    );
  }
}

export default DeparturesPage;
